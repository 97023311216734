import React from "react";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout.jsx";
import root from "window-or-global";
import logo from "ps-assets/img/logo.png";
import img03 from "ps-assets/img/img03.png";
import classNames from "classnames";
import img04 from "ps-assets/img/img04.png";
import Hidden from '@material-ui/core/Hidden';
import img05 from "ps-assets/img/img05.png";
import starIcon from "ps-assets/img/star_icon.png";
import umberlla from "ps-assets/img/umberlla-bg.png";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import completeHealthInsuranceStyle from "ps-assets/jss/completeHealthInsuranceStyle.jsx";
import { landingPageLMSCall } from "util/lmsRequest";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import {  browserWindow } from "util/localStorageHelper";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;

const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};
class BuyCompleteHealthInsurance extends React.Component {
  state = {
    name: ``,
    email: ``,
    mobile: ``,
    pincode: ``,
    privacyPolicy: false,
    submitButton: false,
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  sendToLMS = async () => {
    try {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.name,
        this.state.mobile,
        this.state.email,
        this.state.pincode
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `complete-health-insurance`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId ? response.zohoEntityId : ``
        }${queryParams ? `&${queryParams}` : ``}`,
        `_self`
      );
    } catch (exception) {
      console.error(`except - `, exception);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitButton: true,
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            }
          }
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    const listArray = [
      "India’s first Stand-Alone health insurance company",
      "Hassle-free cashless and reimbursement claim settlement without intervention of TPA",
      "Cashless hospitalization and reimbursement facility in our large network base with more than "+HOSPITALCOUNT+" hospitals across India",
      "Pan India presence with more than 640+ branch offices",
      "89.9% of all cashless claim approval done within 2 hours",
    ];
    return (
      <InstantBuyLayout noFooter={true} noHeader={true}>
        <div className={classes.container}>
          <img className={classes.starLogo} src={logo} alt="Star Health" />
        </div>
        <div
          style={{
            backgroundColor: "#35478A",
          }}
        >
          <div className={classes.rowHeader}>
            <h2 className={classes.rowHeaderh2}>
              When It Comes To Health, Trust Only The Experts!
            </h2>
            <p className={classes.headerWrapperP}>
              Choose From Our Range Of Plans For Your Safety And Well-being
            </p>
          </div>
        </div>
        
        <div className={`${classes.containerFluid} ${classes.bannerBgline}`}>
          <div
            className={`${classes.row} ${classes.starBgImage}`}
            style={{ marginTop: "-10px" }}
          >
            <div
              className={`${classes.container} ${classes.fullWidthContainer}`}
            >
              <div>
              <Hidden smDown>
                <div className={classes.customWidth}>
                  <div className={classes.innerContent}>
                    <div className={classes.displayFlexRow}>
                      <div className={classes.customWidths}>
                        <div className={classes.bannerHints}>
                          <span className={classes.startLeaf}></span>
                          <p className={classes.startLeafP}>
                          89.9% of cashless claims settled in less than 2 hours
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </div>
                      <div className={classes.customWidths1}>
                        <div className={classNames(classes.bannerHints, classes.bannerHintsPoint2)}>
                          <span className={classes.startLeaf}></span>
                          <p className={classNames(classes.startLeafP, classes.custParaWidth01)}>
                            Largest Network hospital base of over {HOSPITALCOUNT}
                            hospitals
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </div>
                      <div
                        className={`${classes.row} ${classes.rowCenter} ${classes.displayFlexTwo}`}
                      >
                        <div className={classes.colmdfour}>
                          <div className={classNames(classes.bannerHints, classes.bannerHintsPoint2)}>
                            <span className={classes.startLeaf}></span>
                            <p className={classes.custParaWidth}>
                              Hassle free and customer friendly direct claim
                              settlement without intervention of TPA
                            </p>
                            <span className={classes.endLeaf}></span>
                          </div>
                        </div>
                        <div
                          className={`${classes.colmdfour} ${classes.paddingRight}`}
                        >
                          <div className={classes.divUmberlla}>
                            <div className={classes.umberllaBg}>
                              <img src={umberlla} />
                            </div>
                            <p className={classes.umberllaParagraph}>
                              Your Loved Ones Deserve The <br />
                              <span className={classes.boldText}>
                                Best Protection From Star Health!
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className={classes.colmdfour}>
                          <div className={classes.bannerHints}>
                            <span className={classes.startLeaf}></span>
                            <p className={classes.custParaWidth}>
                              5.2 million claims settled since inception
                            </p>
                            <span className={classes.endLeaf}></span>
                          </div>
                        </div>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.mdTweleve}>
                          <div className={classes.lastbanner}>
                            <span className={classes.startLeaf}></span>
                            <p className={classes.startLeafP}>
                              Rs. 30,385 Crs of claims amount paid out since
                              inception
                            </p>
                            <span className={classes.endLeaf}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Hidden>
                <Hidden mdUp>
                <GridContainer className={classes.customWidth}>
                  <GridItem className={classes.innerContent}>
                    <GridContainer className={classes.displayFlexRow}>
                      <GridItem className={classes.customWidths}>
                        <div className={classes.bannerHints}>
                          <span className={classes.startLeaf}></span>
                          <p className={classes.startLeafP}>
                          89.9% of cashless claims settled in less than 2 hours
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </GridItem>
                      <GridItem className={classes.customWidths}>
                        <div className={classes.bannerHints}>
                          <span className={classes.startLeaf}></span>
                          <p className={classes.startLeafP}>
                          Largest Network hospital base of over {HOSPITALCOUNT}
                            hospitals
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </GridItem>
                      <GridItem className={classes.customWidths}>
                        <div className={classes.bannerHints}>
                          <span className={classes.startLeaf}></span>
                          <p className={classes.startLeafP}>
                          Hassle free and customer friendly direct claim
                              settlement without intervention of TPA
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </GridItem>
                      <GridItem
                          // className={`${classes.colmdfour} ${classes.paddingRight}`}
                        >
                          <div 
                          // className={classes.divUmberlla}
                          >
                            <div 
                            // className={classes.umberllaBg}
                            style={{textAlign:'center'}}
                            >
                              <img src={umberlla} />
                            </div>
                            <p 
                            className={classes.umberllaParagraph}
                            >
                              Your Loved Ones Deserve The <br />
                              <span 
                              className={classes.boldText}
                              >
                                Best Protection From Star Health!
                              </span>
                            </p>
                          </div>
                        </GridItem>
                        <GridItem className={classes.customWidths}>
                        <div className={classes.bannerHints}>
                          <span className={classes.startLeaf}></span>
                          <p className={classes.startLeafP}>
                          5.2 million claims settled since inception
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </GridItem>
                      <GridItem className={classes.customWidths}>
                        <div className={classes.bannerHints}>
                          <span className={classes.startLeaf}></span>
                          <p className={classes.startLeafP}>
                          Rs. 30,385 Crs of claims amount paid out since
                              inception
                          </p>
                          <span className={classes.endLeaf}></span>
                        </div>
                      </GridItem>
                      {/* <GridItem>
                        <div 
                        // className={classes.mdTweleve}
                        >
                          <div 
                          // className={classes.lastbanner}
                          >
                            <span 
                            className={classes.startLeaf}
                            ></span>
                            <p 
                            // className={classes.startLeafP}
                            >
                              
                            </p>
                            <span className={classes.endLeaf}></span>
                          </div>
                        </div>
                      </GridItem> */}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                </Hidden>
              <div
                  className={`${classes.colmdfour} ${classes.paddingRight} ${classes.customWidthtwo}`}
                >
                  <div className={classes.wrapperForm}>
                    <p style={{ marginBottom: "15px" }}>
                      Fill In Your Details To Get Started
                    </p>
                    <form onSubmit={this.handleSubmit}>
                      <div
                        className={`${classes.formBox} ${classes.mdTweleve}`}
                      >
                        <span className={classes.nameIcons}></span>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          className={classes.formControls}
                          placeholder="Enter Name"
                        />
                        {this.state.submitButton && this.state.name.length<3 && (
                          <p>Name is not in the required format</p>
                        )}
                      </div>
                      <div
                        className={`${classes.formBox} ${classes.mdTweleve}`}
                      >
                        <span className={classes.emailIcons}></span>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          className={classes.formControls}
                          placeholder="Enter E-mail"
                        />
                        {this.state.submitButton &&
                          this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                            <p>Please enter a valid email address.</p>
                          )}
                      </div>
                      <div
                        className={`${classes.formBox} ${classes.mdTweleve}`}
                      >
                        <span className={classes.mobileIcons}></span>
                        <input
                          type="text"
                          id="mobile"
                          className={classes.formControls}
                          placeholder="Enter Mobile Number"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={this.handleChange}
                        />
                        {((this.state.submitButton && this.state.mobile.length < 10) || (this.state.submitButton && this.state.mobile.search(`^[6-9][0-9]{9}$`) == -1)) && (
                            <p>Enter 10 Digit Phone Number</p>
                          )}
                      </div>
                      <div
                        className={`${classes.formBox} ${classes.mdTweleve}`}
                      >
                        <span className={classes.cityIcons}></span>
                        <input
                          type="text"
                          id="pincode"
                          className={classes.formControls}
                          placeholder="Enter PinCode"
                          name="pincode"
                          value={this.state.pincode}
                          onChange={this.handleChange}
                        />
                        {((this.state.submitButton && this.state.pincode.length < 6) || (this.state.submitButton && this.state.pincode.search(`^[1-9][0-9]{5}$`) == -1)) && (
                          <p>Enter 6 Digit Valid PinCode</p>
                          )}
                      </div>
                      <div style={{ display: `inline-flex` }}>
                        <p>
                          <input
                            type="checkbox"
                            onClick={this.handlePrivacyPolicy}
                            checked={this.state.privacyPolicy}
                          />
                          I have read and agree to the
                          <a
                            href="/static-privacy-policy"
                            target="_blank"
                            style={{
                              cursor: `pointer`,
                              textDecoration: "underline",
                              margin: `0px 3px`,
                            }}
                            rel={`noopener noreferrer`}
                          >
                            Privacy Policy
                          </a>
                          I authorize Star Health Insurance to contact me via
                          email or phone or SMS.
                        </p>
                      </div>
                      {this.state.submitButton && !this.state.privacyPolicy && (
                        <p style={{ textAlign: `center` }}>
                          Please Agree to the Terms
                        </p>
                      )}
                      <div className={classes.submitButtonDiv}>
                        <button
                          type="submit"
                          className={classes.submitButton}
                          id="contact-form-submit"
                        >
                          {" "}
                          Submit to Get a Free Quote{" "}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className={classes.containerStyle}>
          <div className={classes.rowflex}>
            <div className={classes.boxesstyle}>
              <div style={{ textAlign: "center" }}>
                <>
                  <center>
                    <img
                      src={img03}
                      style={{
                        maxWidth: `none`,
                      }}
                      alt="Star Health"
                    />
                  </center>
                  <h4
                    style={{
                      fontSize: "13px",
                      color: "#fff",
                      marginTop: "0px",
                      paddingTop: "20px",
                      fontWeight: "400",
                      marginBottom: "0px",
                      paddingBottom: "15px",
                    }}
                  >
                    STAR COMPREHENSIVE INSURANCE POLICY
                  </h4>
                  <p style={{ color: "#76A1E9" }}>
                    In life, health is something that deserves paramount
                    importance. Get the Star Comprehensive Insurance Policy for
                    a secure today and tomorrow.{" "}
                  </p>
                </>
              </div>
            </div>
            <div
              className={classes.boxesstyle}
              style={{
                backgroundColor: "#08a0df",
              }}
            >
              <div style={{ textAlign: "center" }} className={classes.planDetails}>
                <>
                  <center>
                    <img
                      src={img04}
                      style={{ maxWidth: `none` }}
                      alt="Star Health"
                    />
                  </center>
                  <h4
                    style={{
                      fontSize: "13px",
                      color: "#fff",
                      marginTop: "0px",
                      paddingTop: "20px",
                      fontWeight: "400",
                      marginBottom: "0px",
                      paddingBottom: "15px",
                    }}
                  >
                    FAMILY HEALTH OPTIMA INSURANCE PLAN
                  </h4>
                  <p style={{ color: "#ffffff" }}>
                    Ensure your family’s security at all times and stay
                    worry-free with the Family Health Optima Insurance Plan.
                  </p>
                </>
              </div>
            </div>
            <div
              className={classes.boxesstyle}
              style={{
                backgroundColor: "#00183a",
              }}
            >
              <div style={{ textAlign: "center" }} className={classes.planDetails}>
                <>
                  <center>
                    <img
                      src={img05}
                      style={{ maxWidth: `none` }}
                      alt="Star Health"
                    />
                  </center>
                  <h4
                    style={{
                      fontSize: "13px",
                      color: "#fff",
                      marginTop: "0px",
                      paddingTop: "20px",
                      fontWeight: "400",
                      marginBottom: "0px",
                      paddingBottom: "15px",
                    }}
                  >
                    SENIOR CITIZENS RED CARPET HEALTH INSURANCE POLICY
                  </h4>
                  <p style={{ color: "#76A1E9" }}>
                    For seniors over the age of 60, there is no better way to
                    feel safe and secure. Get the Senior Citizens Red Carpet
                    Health Insurance Policy today!
                  </p>
                </>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${classes.containerFluid} ${classes.highlightsBg}`}
        >
          <div className={classes.row}>
            <div
              className={`${classes.container} ${classes.fullWidthContainer}`}
            >
              <div className={`${classes.highlightsBackground} ${classes.row}`}>
                <div
                  className={`${classes.pullRight} ${classes.customWidth} ${classes.highlightsContent} `}
                >
                  <h3
                    className={classes.yourLoved}
                  >
                    Your Loved Ones Deserve The Best Protection From Star
                    Health!
                  </h3>
                  <ul className={classes.highlightsContent}>
                    {listArray.map((listArrayValue, key) => (
                      <li key={key} className={classes.highlightsContentLi}>
                        <img
                          src={starIcon}
                          style={{ maxWidth: `none` }}
                          alt="Star Health"
                        />
                        {listArrayValue}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footerHolder}>
          Registered Corporate Office: No: 1, New Tank Street, Valluvarkottam
          High Road, Nungambakkam, Chennai - 600034. <br />
          Cin No: L66010TN2005PLC056649.
          <br />
          Irdai Registration No: 129. <br />
          Insurance Is The Subject Matter Of Solicitation.
          <br />
          For More Details On Risk Factors, Terms And Conditions Please Read The
          Sales Brochure Carefully Before Concluding A Sale. Irdai Clarifies To
          Public That <br />* Irdai Or Its Officials Do Not Involve In
          Activities Like Sale Of Any Kind Of Insurance Or Financial Products
          Nor Invest Premiums. <br />
          * Irdai Does Not Announce Any Bonus.
          <br />
          * Public Receiving Such Phone Calls Are Requested To Lodge A Police
          Complaint Along With Details Of Phone Call, Number.
          <br />
          SHAI/17-18/Online/LP/BuyOnline/005 | Sales & Services - 044 4674 5800 | Website:
          <a href="http://www.starhealth.in/">www.starhealth.in</a>
        </div>
        <div className={classes.footer}>
          Copyright ©2015. Star Insurance. All rights reserved
        </div>
      </InstantBuyLayout>
    );
  }
}

BuyCompleteHealthInsurance.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(completeHealthInsuranceStyle)(
  BuyCompleteHealthInsurance
);
